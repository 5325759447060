import React, { Component } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import jioncomapny1 from "../images/jion-comapny1.png"
import jioncomapny2 from "../images/jion-comapny2.png"
import jioncomapny3 from "../images/jion-comapny3.png"
import jioncomapny4 from "../images/jion-comapny4.png"
export default class Joincompany extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1
                    }
                }]
        };
        return (
            <section class="myyshopp-joincompany-wrapper">
                <div class="joincompany-main">
                    <h1 class="section-head"> Join companies already using MyyShopp </h1>
                    <Slider {...settings}>
                        <div className="joincompany-img">
                            <img src={jioncomapny1} alt="" class="img-fluid"></img>
                        </div>
                        <div className="joincompany-img">
                            <img src={jioncomapny2} alt="" class="img-fluid"></img>
                        </div>
                        <div className="joincompany-img">
                            <img src={jioncomapny3} alt="" class="img-fluid"></img>
                        </div>
                        <div className="joincompany-img">
                            <img src={jioncomapny4} alt="" class="img-fluid"></img>
                        </div>
                    </Slider>
                </div>
            </section>
        )
    }
}