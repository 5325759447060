import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import successimg from '../images/lokal/success.png';
import { pageHitAPICall } from '../services'
export function getPageHitAPICall() {
    var sellerID = "";
    var moduleID = 8;
    var siteCategoryGroupId = "";
    if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
        siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
    }
    else {
        sellerID = window.sessionStorage.getItem('sellerId')
    }
    pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
}
export default class Success extends Component {
    render() {
        debugger
        let hasMultipleCheckout = window.sessionStorage.getItem('hasMultipleCheckout')
        let themeId = localStorage.getItem('themeId')
        let subDomainFlag = window.sessionStorage.getItem('subDomainFlag');
        return (
            <div className="coverWraper" id="successDiv" style={{ display: this.props.fromPage === "Worldline" ? 'block' : 'none' }}>
                <div className="checkoutSection">
                    <div className="checkoutSuccess">
                        {hasMultipleCheckout === "1" ?
                            <div class="successData">
                                <img src={successimg} alt="" />
                                <h4>YOUR ORDER HAS BEEN PLACED!</h4>
                            </div>
                            :
                            <div class="successData">
                                {themeId === '31' ? "" : <img src={successimg} alt="" />}
                                <h4>YOUR ORDER HAS BEEN PLACED!</h4>
                                <p class="delivery-det" id="divOrderId"></p>
                                <p class="delivery-det" id="divDeliveryTime"></p>
                                <p>Thanks for ordering with {window.sessionStorage.getItem('siteName')}!</p>
                            </div>
                        }
                        <div className="shippingButton mt-0">
                            {(() => {
                                if (themeId === '34'||themeId === '29'|| themeId === '4') {
                                    if (subDomainFlag === '1') {
                                        return (
                                            <Link to="/"><span className="commonButton checkBorderBtn" >Continue Shopping </span></Link>
                                        )
                                    }
                                    else if(localStorage.getItem('hasGlobalSearch') == "true"){
                                        return (
                                            <Link to="/"><span className="commonButton checkBorderBtn" >Continue Shopping </span></Link>
                                        ) 
                                    }
                                    else {
                                        return (
                                            <Link to="/category"><span className="commonButton checkBorderBtn" >Continue Shopping </span></Link>
                                        )
                                    }
                                }
                                else {
                                    return (
                                        <Link to="/"><span className="commonButton checkBorderBtn" >Continue Shopping </span></Link>
                                    )
                                }
                            })()}
                            <Link to="/viewOrderDetails"><span className="commonButton">View Order</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}