import React, { Component } from 'react'
import { getData } from '../services'

import {getCartItemsMultiple} from '../services'
import StripeOrderSuccess from './stripeOrderSuccess';
// import "../js/js.stripe.js";
export default class StripeSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
          paymentStatus: '',
          stripeId:'',
          buyerDeliveryAddressId:'',
          storeId:''
        }
      }
    componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({
            stripeId:params.stripeId,
            buyerDeliveryAddressId : params.buyerDeliveryAddressId,
            itemBeforePaymentGroupCode : params.itemBeforePaymentGroupCode,
            storeId:params.storeId
        },()=>{
            this.saleConfirm()
        })
    }
    async saleConfirm(){
        var postData = {
            stripeStoreId: this.state.storeId,
            functionName: "saleConfirm",
            device: "Web",
            locationId: localStorage.getItem('locationId'),
            buyerId: localStorage.getItem('userId'),
            buyerDeliveryAddressId: this.state.buyerDeliveryAddressId,
            siteId: localStorage.getItem('siteId'),
            cityId: localStorage.getItem('cityId'),
            razorPayDetails: [],
            worldLineDetails:[],
            stripeId:this.state.stripeId,
            paymentId: 5,
            commonItem: JSON.parse(localStorage.getItem('commonItemArrayWL')),
            restaurant: JSON.parse(localStorage.getItem('restaurantArrayWL')),
            grocery: JSON.parse(localStorage.getItem('groceryArrayWL')),
            hasLocation: localStorage.getItem('hasLocation'),
            timeZone: new Date().getTimezoneOffset().toString(),
            itemBeforePaymentGroupCode: this.state.itemBeforePaymentGroupCode
          }
          getCartItemsMultiple(postData).then((data) => {
            console.log("dataaaaaaa",data)
            // alert(data)    
            //  alert(" data.data.success......." + data.data.success)           
            if (data.data.success == 1) {
              localStorage.removeItem('commonItemArrayWL')
              localStorage.removeItem('restaurantArrayWL')
              localStorage.removeItem('groceryArrayWL')
              localStorage.setItem('orderSummery', JSON.stringify(data.data.result));
              if (this.state.storeId != '') {
                var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
                var cookieNewGroceryItemValue = []
                var j = 0
                for (var i = 0; i < selectGroceryItemCart.length; i++) {
                  if (this.state.storeId != selectGroceryItemCart[i].storeId) {
                    cookieNewGroceryItemValue[j] =
                      {
                        storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                        item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                        item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                        quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                        item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                        store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                        pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                        stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                        item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                        itemNo: selectGroceryItemCart[i].itemNo.toString().trim()
                      }
                    j = parseInt(j) + 1
                  }
                }
                localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewGroceryItemValue))

                var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
                var cookieNewCommonItemValue = []
                j = 0
                for (var i = 0; i < selectCommonItemCart.length; i++) {
                  if (this.state.storeId != selectCommonItemCart[i].storeId) {
                    cookieNewCommonItemValue[j] =
                      {
                        storeId: selectCommonItemCart[i].storeId.toString().trim(),
                        itemId: selectCommonItemCart[i].itemId.toString().trim(),
                        itemName: selectCommonItemCart[i].itemName.toString().trim(),
                        quantity: selectCommonItemCart[i].quantity.toString().trim(),
                        itemTitle: selectCommonItemCart[i].itemTitle,
                        brand: selectCommonItemCart[i].brand,
                        sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                        sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                        cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                        itemMasterId: selectCommonItemCart[i].itemMasterId.toString().trim(),
                        netAmount: selectCommonItemCart[i].netAmount.toString().trim(),
                        mrp: selectCommonItemCart[i].mrp.toString().trim(),
                        itemImage: selectCommonItemCart[i].itemImage.toString().trim(),
                        itemNo: selectCommonItemCart[i].itemNo.toString().trim()
                      }
                    j = parseInt(j) + 1
                  }
                }
                localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))

                var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
                var cookieNewRestaurantValue = []
                j = 0
                for (var i = 0; i < selectRestaurantCart.length; i++) {
                  if (this.state.storeId != selectRestaurantCart[i].storeId) {
                    cookieNewRestaurantValue[j] =
                      {
                        storeId: selectRestaurantCart[i].storeId.toString().trim(),
                        itemId: selectRestaurantCart[i].itemId.toString().trim(),
                        itemName: selectRestaurantCart[i].itemName.toString().trim(),
                        quantity: selectRestaurantCart[i].quantity.toString().trim(),
                        itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                        itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                        itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                        storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                        itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                        toppingsData: selectRestaurantCart[i].toppingsData.toString().trim()
                      }
                    j = parseInt(j) + 1
                  }
                }
                localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))
              }
              else {
                localStorage.removeItem('selectedCartProduct')
                localStorage.removeItem('selectCommonItemCart')
                localStorage.removeItem('selectRestaurantCart')
              }
              var orderSummery = localStorage.getItem('orderSummery')
              var orderSummeryData = JSON.parse(orderSummery);
              var postData = {
                functionName: "sendMail",
                result: orderSummeryData
              }
              getCartItemsMultiple(postData).then((data) => {

              })
              this.setState({
                paymentStatus: 'S',
              });
            }
            else {
              alert(data.data.errorMessage)
            }
          });
    }
    render() {
        return (
          (() => {
            if (this.state.paymentStatus === 'S') {
              return (
                <StripeOrderSuccess></StripeOrderSuccess>
              )
            }
            else {
              return (
                <div><center><h1>Please do not refresh this page. Processing...</h1></center></div>
              )
            }
          })()
        )
      }
}